import { Toast } from "vant";
export default {
  methods: {
    onMessage(val, sign) {
      let num = sign ? 1 : 3;
      if (val.cmd == "101") {
        this.delApp(val.data, sign, num);
      } else if (val.cmd == "102") {
        this.changeAppState(JSON.parse(JSON.stringify(val.data)), sign, num);
      } else if (val.cmd == "103") {
        // 列表刷新之后，更改状态，首先从本地获取用户之前操作过应用状态，然后再更改客户端返回安装应用状态
        let list = this.sessionData();
        this.refreshList(list.str, sign, num);
        this.handleForEvent(val.data.appList, sign, num);
        this.loading = false;
      }
      //触发视图更新
      this.key = Date.now();
    },
    // 刚进来刷新列表状态
    refreshList(val, sign, num) {
      // 首先获取到客户端返回的安装应用+会话存储中是否有数据进行对比
      // 会话存储中存储用户操作安装应用  客户端返回数据为已安装应用 所以进行一个同时对比
      let arr = [];
      if (val?.length > 0) {
        val.forEach((item) => {
          arr.push(item.pkg);
        });
      }
      for (let i = 0; i < num; i++) {
        let name = this.getName(sign, i);
        this[name].forEach((it) => {
          if (arr.includes(it.pkg)) {
            this.$set(it, "state", 4);
          }
        });
      }
    },

    // 客户端主动安装和卸载应用
    delApp(data, sign, num) {
      for (let i = 0; i < num; i++) {
        let index = -1;
        let name = this.getName(sign, i);
        this[name].forEach((item, ind) => {
          if (item.pkg == data.pkg) {
            if (data.action == 1) {
              let flag = Number(item.verCode) - Number(data.verCode) > 0;
              this.$set(item, "state", flag ? 3 : 2);
              index = ind;
            } else if (data.action == 2) {
              item.state = 0; //下载
            }
          }
        });
        // 如果该应用状态为打开，则放到列表最后面
        index > -1 ? this.choosePos(this[name][index], name, index) : "";
        //  如果应用卸载，需要判断会话存储中是否有该应用，如果有删除，不然会显示为安装状态而不是下载
        if (data.action == 2) {
          let obj = this.sessionData();
          if (obj.pkgs.includes(data.pkg)) {
            let ind = obj.pkgs.indexOf(data.pkg);
            obj.str.splice(ind, 1);
            sessionStorage.setItem("appList", JSON.stringify(obj.str));
          }
        }
      }
    },
    // 从客户端获取那些应用被安装，更改该应用状态
    handleForEvent(androidRes, sign, num) {
      // sign  true 远程推送应用  false 应用推荐应用
      let androidPkg = (androidRes || []).map((i) => {
        return i.pkg;
      });
      for (let i = 0; i < num; i++) {
        let name = this.getName(sign, i);
        for (let i = 0; i < this[name]?.length; i++) {
          if (androidPkg.includes(this[name][i].pkg)) {
            (androidRes || []).forEach((item) => {
              if (item.pkg == this[name][i].pkg) {
                let flag =
                  Number(this[name][i].verCode) - Number(item.verCode) > 0;
                this[name][i].state = flag ? 3 : 2;
              }
            });
          }
        }
        this.dealOpenPos(name);
      }
      //处理好之后，将打开状态的应用放到列表的最后面
    },
    // 打开&更新状态的应用放到列表的最后面
    dealOpenPos(name) {
      let data = JSON.parse(JSON.stringify(this[name]));
      let arrOpen = []; // 打开状态应用
      let arrDown = []; // 安装&下载&下载中&更新应用
      data.forEach((item) => {
        if (item.state == 2 || item.state == 3) {
          arrOpen.push(item);
        } else {
          arrDown.push(item);
        }
      });
      this[name] = [...arrDown, ...arrOpen];
    },
    //h5主动点击下载等按钮返回102 应用状态更新
    changeAppState(res, sign, num) {
      for (let i = 0; i < num; i++) {
        let index = -1;
        let name = this.getName(sign, i);

        this[name].forEach((item, ind) => {
          index = ind;
          if (item.pkg == res.pkg) {
            this.$set(this[name][ind], "state", res.state);
            // 如果是下载中，远程推送手机端显示下载百分比
            if (res.state == 1) {
              let da = JSON.parse(JSON.stringify(this[name][ind]));
              let describe = res.describe == "下载中" ? 0 : res.describe * 1;
              this.$set(da, "describe", describe);
              this[name].splice(ind, 1, JSON.parse(JSON.stringify(da)));
            }
          }
        });
        this.choosePos(res, name, index);
      }
      // 如果是安装状态，存储到本次，以便刷新时更改状态，客户端没有给安装状态
      if (res.state == 4) {
        let obj = this.sessionData();
        if (!obj.pkgs.includes(res.pkg)) {
          obj.str.push(res);
          sessionStorage.setItem("appList", JSON.stringify(obj.str));
        }
      }
    },
    // 主动点击安装、更新&客户端主动安装应用,放到列表最后端
    choosePos(val, name, ind) {
      if (val.state == 2 || val.state == 3) {
        let item = JSON.parse(JSON.stringify(this[name][ind]));
        this[name].splice(ind, 1);
        this[name].push(item);
      }
    },

    updateClick(item, flag) {
      let url = sessionStorage.getItem("apiUrls") || "";
      let sign = !flag || (url.length > 0 && flag);
      let param = {
        cmd: 2,
        data: {
          ...item,
        },
      };
      sign ? this.$emit("sendSocket", param) : "";
      let flags = flag && url.length == 0;
      Toast.clear();
      let mes =
        this.$route.query?.flag == "true" && flags
          ? "请返回遥控首页重新连接设备"
          : "请重新扫码连接设备";
      flags ? Toast.fail(`当前设备状态异常，${mes}`) : "";
    },

    getName(sign, i) {
      let name = "";
      if (sign) {
        name = "list";
      } else {
        name = i == 0 ? "appList" : i == 1 ? "movieList" : "swipeList";
      }
      return name;
    },
    close() {
      this.appList.forEach((item) => {
        item.state = 0;
      });
      this.swipeList.forEach((item) => {
        item.state = 0;
      });
      this.movieList.forEach((item) => {
        item.state = 0;
      });
      this.key = Date.now();
      if (this.$refs.selectPopupRef.popupShow == true) {
        this.$refs.selectPopupRef.popupClose();
      }
      this.socket = null;
      this.apiUrls = [];
      this.curUrlIndex = 0;
    },
    onError() {
      this.socket = null;
      this.curUrlIndex = 0;
      this.apiUrls = [];
    },

    connected() {
      this.$refs.selectPopupRef.popupClose();
    },
    onOpen() {
      this.$refs.selectPopupRef.loading = false;
      this.$refs.selectPopupRef.popupClose();
    },
    // 获取会话存储中数据
    sessionData() {
      let str = sessionStorage.getItem("appList") || [];
      if (!Array.isArray(str)) {
        str = JSON.parse(str);
      }
      let pkgs = [];
      str.forEach((item) => {
        pkgs.push(item.pkg);
      });
      let data = {
        pkgs: pkgs,
        str: str,
      };
      return data;
    },
  },
};
