<template>
  <page @refresh="onRefresh" v-if="swipeList" v-cloak>
    <div class="page-wrapper apply-wrapper">
      <!--顶部轮播图-->
      <div>
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white" v-if="swipeList.length > 0">
          <van-swipe-item @click="detailClick(item)" v-for="item in swipeList" :key="item.sort">
            <img :src="item.img" class="swipeImg" />
          </van-swipe-item>
        </van-swipe>
      </div>
      <!--中间4个推荐位-->
      <div :key="key">
        <div class="hotApply">
          <p class="hotTitle" v-if="title.length > 1">{{ title[1].name }}</p>

          <div class="app" v-if="appList.length > 0">
            <div class="iqy" v-for="item in appList" :key="item.id" @click="detailClick(item)">
              <!--图标-->
              <p class="icon-com">
                <img width="100%" :src="item.icon" />
              </p>
              <!--名称-->
              <span class="name">{{ item.name || "--" }}</span>
              <!--按钮-->
              <template v-if="apiUrls.length > 0">
                <button class="button_load ellipsis" :style="{
    color: buttonColor(item.state),
    border: `1px solid ${buttonColor(item.state)}`,
    background:
      item.state == 1 ? 'rgba(223, 244, 255)' : '#FFF',
  }" @click.stop="updateClick(item)">
                  {{ buttonText(item.state) }}
                </button>
              </template>
              <template v-else>
                <button class="button_load ellipsis" @click.stop="noUrlDownLoad" v-if="appList.length">
                  下载
                </button>
              </template>
            </div>
          </div>
          <!--无应用状态-->
          <div class="nodata" v-else>
            <van-loading v-if="loading" size="24px">加载中...</van-loading>
            <span v-else>暂无应用</span>
          </div>
        </div>
        <!--下面列表推荐位-->
        <div class="watchFilm">
          <!--板块标题-->
          <p class="watchTitle" v-if="title.length > 2">{{ title[2].name }}</p>
          <template v-if="movieList.length > 0">
            <div class="movieCom" v-for="item in movieList" :key="item.id" @click="detailClick(item)">
              <div class="movie-left">
                <p class="icon-com">
                  <img width="100%" :src="item.icon" />
                </p>
                <div class="movie-content">
                  <p class="name ellipsis">
                    {{ item.name }}
                  </p>
                  <p class="apkExplain ellipsis">
                    {{ item.apkExplain }}
                  </p>
                  <p class="another">
                    {{ item.apkSize }}&nbsp;&nbsp;&nbsp;&nbsp;{{
    `${item.apkCount}次`
  }}
                  </p>
                </div>
              </div>
              <!--按钮-->
              <template v-if="apiUrls.length > 0">
                <button class="button_load_bottom ellipsis" :style="{
    color: buttonColor(item.state),
    border: `1px solid ${buttonColor(item.state)}`,
    background:
      item.state == 1 ? 'rgba(223, 244, 255)' : '#FFF',
  }" @click.stop="updateClick(item)">
                  {{ buttonText(item.state, 1) }}
                </button>
              </template>
              <template v-else>
                <button class="button_load_bottom ellipsis" v-if="movieList.length" :style="{
    color: '#4CBBF9',
    border: `1px solid #4CBBF9`,
    background:
      item.state == 1 ? 'rgba(223, 244, 255)' : '#FFF',
  }" @click.stop="noUrlDownLoad">
                  下载到电视
                </button>
              </template>
            </div>
          </template>
          <div class="nodata" v-else>
            <van-loading v-if="loading" size="24px">加载中...</van-loading>
            <span v-else>暂无应用</span>
          </div>
        </div>
      </div>
    </div>
    <selectPopup :submit="selectSubmit" ref="selectPopupRef" />
  </page>
</template>
<script>
import { get, post } from "@/utils/request";
import selectPopup from "@/components/selectPopup.vue";
import { Toast } from "vant";
import commonJs from '@/mixins/common.js'
export default {
  components: { selectPopup },
  mixins: [commonJs],
  data() {
    return {
      swipeList: [],
      appList: [],
      movieList: [],
      apiUrls: [],
      waitList: [],
      title: [],
      curApiItem: null,
      curHostIndex: 0,
      curUrlIndex: 0,
      socket: null,
      key: Date.now(),
      loading: false,
      timer: null,
      onOpenSet: null,
    };
  },
  created() {
    this.onRefresh();
    this.getModuleTitle();
    this.socket = null;
    this.curUrlIndex = 0;
  },
  methods: {
    //按钮文字
    buttonText(state, sign) {
      switch (state) {
        case 0:
          return sign == 1 ? "下载到电视" : "下载";
        case 1:
          return "下载中...";

        case 2:
          return "打开";

        case 3:
          return "更新";

        case 4:
          return "安装";
      }
    },
    //按钮颜色
    buttonColor(state) {
      switch (state) {
        case 0:
          return "#4CBBF9";

        case 1:
          return "#4CBBF9";

        case 2:
          return "#4D4F51";

        case 3:
          return "#FF5D05";

        case 4:
          return "#4CBBF9";
      }
    },

    //itemEquipmentIndex-设备index,   curWsIndex-设备地址index
    handleEquipWS(data, itemEquipmentIndex, curWsIndex) {
      // debugger
      console.log("开始轮询");
      console.log(
        `当前设备index为${itemEquipmentIndex}, 地址index为${curWsIndex}`
      );
      let itemEquipment = data[itemEquipmentIndex]; // 单个设备信息
      let wsList = itemEquipment.extra;
      let socket = new WebSocket(wsList[curWsIndex].link);
      console.log(socket.url, "发起新的socket请求");
      //模仿websocket超时处理
      this.timer = setTimeout(() => {
        // debugger
        // console.log(socket, socket.url, socket.readyState, "TTTTTTTTTTTTTT");
        if (socket && socket.readyState === 0) {
          //readyState 0 表示正在建立连接
          console.log("处理webSocket超时!");
          socket.close(3000, "处理webSocket超时!");
        }
      }, 3000);
      // console.log(socket.url, "socket.readyState", socket.readyState,);
      //socket连接通了则继续下一个
      socket.onopen = (e) => {
        // debugger
        console.log(e.currentTarget.url, "socket连接成功");
        // debugger
        clearTimeout(this.timer);
        if (e.currentTarget.url == socket.url) {
          data[itemEquipmentIndex].status = 0;
          this.$set(wsList[curWsIndex], "enable", true);
          socket && socket.close(3000, "客户端主动关闭");
          this.onOpenSet = setTimeout(() => {
            // debugger
            // wsList[curWsIndex].enable = true
            this.handleCommModule(data, itemEquipmentIndex, curWsIndex);
          }, 1100);
        }
      };
      //socket连接失败，断开连接，将此WS地址状态设为 -1，代表不可用
      socket.onerror = (e) => {
        // debugger
        console.log(e, socket, "socket出错了");
        clearTimeout(this.timer);
        if (this.onOpenSet) {
          clearTimeout(this.onOpenSet);
        }
        if (e.currentTarget.url == socket.url) {
          curWsIndex++;
          setTimeout(() => {
            if (curWsIndex <= wsList.length - 1) {
              socket = null;
              this.handleEquipWS(data, itemEquipmentIndex, curWsIndex);
            } else {
              if (data[itemEquipmentIndex].status === 0) {
                this.handleCommModule(data, itemEquipmentIndex, curWsIndex);
              } else {
                data[itemEquipmentIndex].status = -1;
                this.handleCommModule(data, itemEquipmentIndex, curWsIndex);
              }
              // console.log(data, `轮询第${itemEquipmentIndex}次`)
            }
          }, 1000);
        }
      };

      socket.onclose = (e) => {
        // debugger
        console.log(
          e.target.readyState,
          "连接关闭了,状态码为",
          e.code,
          "地址：",
          e.currentTarget.url
        );
      };
    },
    //设备index + 1
    handleCommModule(data, itemEquipmentIndex, curWsIndex) {
      itemEquipmentIndex++;
      if (itemEquipmentIndex <= data.length - 1) {
        curWsIndex = 0;
        this.handleEquipWS(data, itemEquipmentIndex, curWsIndex);
      } else {
        //处理最终可用的设备列表 根据status == -1 为不可用，进行过滤
        console.log(data, "过滤前的设备");
        const promise = new Promise((resolve) => {
          // setTimeout(() => {
          let list = data.filter((item) => {
            return item.status === 0;
          });
          console.log(list, "list home");
          resolve(list);
          // }, 3000);
        });
        promise.then((res) => {
          console.log(res, "过滤完可以连接的设备");

          if (res.length === 0) {
            this.$toast("搜索设备失败，请检查手机与设备是否在同一局域网内");
            //最后剩余可用连接只有1个时，直接连接
          } else if (res.length === 1) {
            this.handleHasOne(res);
            //最后剩余多个可用连接时，弹出弹框，让用户选择
          } else {
            // 查询有效设备中是否有上次连接过的设备
            if (localStorage.getItem("lastUrl")) {
              // 有则将此设备提至第一位
              let lastConnect =
                JSON.parse(localStorage.getItem("lastUrl")) || "";
              res.forEach((item, index) => {
                if (item.hostIp == lastConnect.hostIp) {
                  res.unshift(...res.splice(index, 1));
                  sessionStorage.setItem("curIndex", 0);
                }
              });
              this.onRefresh();
            } else {
              //没有则将上次连接设备信息进行删除
              localStorage.removeItem("lastUrl");
            }
            //调起弹窗
            const dialog = this.$refs.selectPopupRef;
            dialog.apilist = this.filterUrl(res) || [];
            console.log(dialog.apilist, "dialog.apilist home");
            dialog.curIndex =
              JSON.parse(sessionStorage.getItem("curIndex")) ?? null;
            this.$nextTick(() => {
              dialog.show();
              Toast.clear();
            });
          }
        });
      }
    },

    handleHasOne(data) {
      //获取弹窗节点
      const dialog = this.$refs.selectPopupRef;
      //处理WS地址，返回一个只含有WS地址的数组
      let urls = data[0].extra.map((i) => {
        return i.link;
      });
      //存入信息
      sessionStorage.setItem("apiUrls", JSON.stringify(urls));
      sessionStorage.setItem("curIndex", JSON.stringify(0));
      sessionStorage.setItem("curApiItem", JSON.stringify(data[0]));
      this.onRefresh();
      //弹窗渲染 + 显示
      dialog.height = "30%";
      dialog.title = "";
      dialog.closeable = false;
      dialog.btnShow = true;
      dialog.apilist = this.filterUrl(data) || [];
      dialog.curIndex = JSON.parse(sessionStorage.getItem("curIndex")) ?? null;
      this.$nextTick(() => {
        dialog.show();
        Toast.clear();
      });
    },

    noUrlDownLoad() {
      Toast.loading({
        message: "扫描中...",
        forbidClick: true,
        duration: 0,
      });
      post("/push/web/equipment/list", {})
        .then((res) => {
          if (res.code === 200) {
            if (Array.isArray(res.data) && res.data.length > 0) {
              //   console.log(res.data, "点击设备 进来了 拿到结果 看到数据了");
              let data = res.data;
              // let data = [{
              //   deviceId: '2f86bb2ff41cc0141701febc3a3d0eec',
              //   deviceName: '智能设备',
              //   extra: [{
              //     link: 'ws://192.168.3.238:10002'
              //   }],
              //   hostIp: '192.168.3.238',
              //   mac: '80:90:24:aa:cb:40'
              // }]
              let itemEquipmentIndex = 0;
              let curWsIndex = 0;
              this.handleEquipWS(data, itemEquipmentIndex, curWsIndex);
            } else {
              this.$toast("搜索设备失败，请检查手机与设备是否在同一局域网内");
            }
          }
        })
        .catch((err) => {
          console.log(err, "err");
          Toast.clear();
        });
    },

    selectSubmit(data) {
      console.log(data, "data");
      return new Promise((resolve) => {
        setTimeout(() => {
          sessionStorage.setItem("apiUrls", JSON.stringify(data.urls));
          sessionStorage.setItem("curIndex", JSON.stringify(data.curIndex));
          sessionStorage.setItem("curApiItem", JSON.stringify(data.curApiItem));
          this.onRefresh();
          this.getAppHasState();
          this.handleWS();
          resolve(true);
        }, 1000);
      });
    },
    //弹窗前重新遍历一遍数组，根据之前遍历时存放的enable属性来判断此设备下多个link中的无效link，并将其过滤
    filterUrl(list) {
      list.forEach((item, idx) => {
        item.extra = item.extra.filter((urls, index) => {
          return urls.enable == true;
        });
        if (!item.extra.length) {
          list.splice(idx, 1);
        }
      });

      return list;
    },

    onRefresh() {
      this.apiUrls = JSON.parse(sessionStorage.getItem("apiUrls")) || [];
      this.curApiItem = JSON.parse(sessionStorage.getItem("curApiItem")) || [];
    },

    detailClick(item) {
      sessionStorage.setItem("curAppItem", JSON.stringify(item));
      this.$router.push(`/Detail?id=${item.id}`);
    },

    //获取模块标题
    getModuleTitle() {
      let that = this
      let params = {
        menu: true,
        size: 200,
        current: 1,
        type: 0,
      };
      get("/push/type/query", params).then((res) => {
        if (res.code == 200) {
          that.title = res.data.records
          this.getAppHasState()
        }
      });
    },
    //获取列表应用状态
    getAppHasState() {
      this.loading = true;
      console.log(22222, "经来了");
      let that = this;
      let data = {
        size: 200,
        current: 1,
      };
      let param = [
        get("/push/type/app/query", { ...data, typeId: this.title[1].id }),
        get("/push/type/app/query", { ...data, typeId: this.title[2].id }),
        get("/push/type/app/query", { ...data, typeId: this.title[0].id })
      ];
      Promise.all(param)
        .then((resp) => {
          if (resp[0].code == 200) {
            let arr =
              resp[0].data.length >= 4
                ? resp[0].data.slice(0, 4)
                : resp[0].data;
            this.appList = arr;
            this.movieList = JSON.parse(JSON.stringify(resp[1].data));
            this.swipeList = JSON.parse(JSON.stringify(resp[2].data))
            this.dealState()
            if (this.apiUrls.length > 0) {
              this.handleWS();
            }
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err, "err");
          this.loading = false;
        });
    },

    // 更改所有的状态
    dealState() {
      for (let i = 0; i < 3; i++) {
        let name = i == 0 ? 'appList' : i == 1 ? 'movieList' : 'swipeList'
        this[name].forEach(item => {
          item.state = 0
        })
      }
    },

    // 打开 下载 更新 相关处理
    removeEquipment(url) {
      post("/push/equipment/deletelink", {
        deviceId: this.curApiItem.deviceId,
        link: url,
      });
    },

    handleWS() {
      let param = {
        cmd: 3,
        data: {
          system: false,
        },
      };
      this.$emit("connectSocket", this.curUrlIndex, param);
    },

    updateClick(item) {
      let param = {
        cmd: 2,
        data: {
          ...item,
        },
      };
      this.$emit("sendSocket", param);
    },

  },

  beforeDestroy() {
    clearTimeout();
    this.socket = null;
    this.curUrlIndex = 0;
    if (this.apiUrls.length) {
      this.$store.commit("setAppList", this.appList);
      this.$store.commit("setMovieList", this.movieList);
      this.$store.commit("setSwipeList", this.swipeList);
    }
  },
};
</script>
<style lang="less" scoped>
[v-cloak] {
  display: none;
}

.apply-wrapper {
  background-color: #f6f8fa;

  .swipe-com {
    font-size: 0;
    height: 211px;
    overflow: hidden;
  }

  .my-swipe {

    .van-swipe-item {
      .swipeImg {
        width: 100%;
        height: 210px;
      }
    }
  }

  .ellipsis {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .hotApply {
    padding: 0px 5px;

    .hotTitle {
      margin: 10px;
      color: #2a2a2a;
      font-size: 4.27vw;
    }

    .app {
      width: 100%;
      background-color: #fff;
      display: flex;
      padding: 10px 0px;
      overflow: hidden;

      .iqy {
        padding: 0 4.13vw;
        text-align: center;
        display: flex;
        flex: 1;
        flex-direction: column;
        align-items: center;
        font-size: 0;
        box-sizing: border-box;

        .icon-com {
          width: 16vw;
          height: 16vw;
          border-radius: 10px;
          margin: 0 auto;
          overflow: hidden;
        }

        .name {
          width: 16vw;
          font-size: 3.73vw;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #2a2a2a;
          margin: 5px 0px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .button_load {
          width: 16vw;
          height: 6vw;
          color: #4cbbf9;
          border: 1px solid #4cbbf9;
          font-size: 3.73vw;
          background: #fff;
          border-radius: 30px;
        }
      }
    }

    .app::-webkit-scrollbar {
      display: none;
    }
  }

  .watchFilm {
    width: 100%;
    overflow: hidden;

    .watchTitle {
      margin: 10px 15px;
      color: #2a2a2a;
      font-size: 4.27vw;
    }

    .movieCom {
      width: 100%;
      padding: 5px;
      background-color: #fff;
      border-bottom: 1px solid #ececec;
      display: flex;
      align-items: center;
      justify-content: space-between;
      overflow: hidden;
      font-size: 0;

      &:last-child {
        border-bottom: none;
      }

      .movie-left {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        overflow: hidden;
        align-items: center;
        font-size: 0;

        .icon-com {
          width: 16vw;
          height: 16vw;
          border-radius: 10px;
          overflow: hidden;
        }

        .movie-content {
          flex: 1;
          overflow: hidden;
          margin-left: 10px;
          font-size: 0;

          .name {
            font-size: 3.73vw;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #2a2a2a;
          }

          .apkExplain {
            font-size: 2.93vw;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #898c8f;
            margin: 4px 0px;
          }

          .another {
            font-size: 2.93vw;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #c9cfd4;
          }
        }
      }

      .button_load_bottom {
        width: 20vw;
        height: 5.5vw;
        color: #4cbbf9;
        border: 1px solid #4cbbf9;
        font-size: 3.3vw;
        background: #fff;
        border-radius: 30px;
      }
    }
  }

  .movieButton {
    width: 18.67vw;
    height: 2.9vh;
    font-size: 3.2vw;
    margin-left: 5px;
    color: #4cbbf9;
    background: #def3ff;
    border: 1px solid #4cbbf9;
    border-radius: 30px;
  }

  .nodata {
    width: 100%;
    text-align: center;
    padding: 40px 0px;
    background: #fff;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #898c8f;
  }
}
</style>
