var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.swipeList)?_c('page',{on:{"refresh":_vm.onRefresh}},[_c('div',{staticClass:"page-wrapper apply-wrapper"},[_c('div',[(_vm.swipeList.length > 0)?_c('van-swipe',{staticClass:"my-swipe",attrs:{"autoplay":3000,"indicator-color":"white"}},_vm._l((_vm.swipeList),function(item){return _c('van-swipe-item',{key:item.sort,on:{"click":function($event){return _vm.detailClick(item)}}},[_c('img',{staticClass:"swipeImg",attrs:{"src":item.img}})])}),1):_vm._e()],1),_c('div',{key:_vm.key},[_c('div',{staticClass:"hotApply"},[(_vm.title.length > 1)?_c('p',{staticClass:"hotTitle"},[_vm._v(_vm._s(_vm.title[1].name))]):_vm._e(),(_vm.appList.length > 0)?_c('div',{staticClass:"app"},_vm._l((_vm.appList),function(item){return _c('div',{key:item.id,staticClass:"iqy",on:{"click":function($event){return _vm.detailClick(item)}}},[_c('p',{staticClass:"icon-com"},[_c('img',{attrs:{"width":"100%","src":item.icon}})]),_c('span',{staticClass:"name"},[_vm._v(_vm._s(item.name || "--"))]),(_vm.apiUrls.length > 0)?[_c('button',{staticClass:"button_load ellipsis",style:({
  color: _vm.buttonColor(item.state),
  border: ("1px solid " + (_vm.buttonColor(item.state))),
  background:
    item.state == 1 ? 'rgba(223, 244, 255)' : '#FFF',
}),on:{"click":function($event){$event.stopPropagation();return _vm.updateClick(item)}}},[_vm._v(" "+_vm._s(_vm.buttonText(item.state))+" ")])]:[(_vm.appList.length)?_c('button',{staticClass:"button_load ellipsis",on:{"click":function($event){$event.stopPropagation();return _vm.noUrlDownLoad.apply(null, arguments)}}},[_vm._v(" 下载 ")]):_vm._e()]],2)}),0):_c('div',{staticClass:"nodata"},[(_vm.loading)?_c('van-loading',{attrs:{"size":"24px"}},[_vm._v("加载中...")]):_c('span',[_vm._v("暂无应用")])],1)]),_c('div',{staticClass:"watchFilm"},[(_vm.title.length > 2)?_c('p',{staticClass:"watchTitle"},[_vm._v(_vm._s(_vm.title[2].name))]):_vm._e(),(_vm.movieList.length > 0)?_vm._l((_vm.movieList),function(item){return _c('div',{key:item.id,staticClass:"movieCom",on:{"click":function($event){return _vm.detailClick(item)}}},[_c('div',{staticClass:"movie-left"},[_c('p',{staticClass:"icon-com"},[_c('img',{attrs:{"width":"100%","src":item.icon}})]),_c('div',{staticClass:"movie-content"},[_c('p',{staticClass:"name ellipsis"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('p',{staticClass:"apkExplain ellipsis"},[_vm._v(" "+_vm._s(item.apkExplain)+" ")]),_c('p',{staticClass:"another"},[_vm._v(" "+_vm._s(item.apkSize)+"    "+_vm._s(((item.apkCount) + "次"))+" ")])])]),(_vm.apiUrls.length > 0)?[_c('button',{staticClass:"button_load_bottom ellipsis",style:({
  color: _vm.buttonColor(item.state),
  border: ("1px solid " + (_vm.buttonColor(item.state))),
  background:
    item.state == 1 ? 'rgba(223, 244, 255)' : '#FFF',
}),on:{"click":function($event){$event.stopPropagation();return _vm.updateClick(item)}}},[_vm._v(" "+_vm._s(_vm.buttonText(item.state, 1))+" ")])]:[(_vm.movieList.length)?_c('button',{staticClass:"button_load_bottom ellipsis",style:({
  color: '#4CBBF9',
  border: "1px solid #4CBBF9",
  background:
    item.state == 1 ? 'rgba(223, 244, 255)' : '#FFF',
}),on:{"click":function($event){$event.stopPropagation();return _vm.noUrlDownLoad.apply(null, arguments)}}},[_vm._v(" 下载到电视 ")]):_vm._e()]],2)}):_c('div',{staticClass:"nodata"},[(_vm.loading)?_c('van-loading',{attrs:{"size":"24px"}},[_vm._v("加载中...")]):_c('span',[_vm._v("暂无应用")])],1)],2)])]),_c('selectPopup',{ref:"selectPopupRef",attrs:{"submit":_vm.selectSubmit}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }